<template>
  <div class="business">
    <div class="bgContainer">
      <img class="bgImage" src="@/assets/pageBg/business.jpg" />
    </div>
    <div class="content_Container">
      <div class="project introduce">
        <h1>创业孵化</h1>
        <div class="project_list">
          <template v-for="(item, index) in projectList">
            <div class="project_block">
              <div class="block_left">
                <img :src="item.img">
              </div>
              <div class="block_right">
                <div class="right_title"><span>{{item.title}}</span></div>
                <div class="right_text">{{item.text}}</div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="bottom_bg">
        <div class="service introduce">
          <h1>企业服务</h1>
          <div class="service_list project_list">
            <ol>
              <template v-for="item in serviceList">
                <li>{{item.text}}</li>
              </template>
            </ol>
          </div>
        </div>
        <div class="technology introduce">
          <h1>技术拓展</h1>
          <div class="technology_list">
            <div class="line"></div>
            <div class="list_container">
              <template v-for="item in technologyList">
                <div class="technology_block">
                  <div class="block_img">
                    <img :src="item.img">
                  </div>
                  <div class="block_text">
                    <p class="title_p">{{item.title}}</p>
                    <template v-for="val in item.child">
                      <p>{{val.text}}</p>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="encounter">
        <div class="container_size">
          <div class="text_container">
            <h6>现在，非常期待与您的再一次邂逅</h6>
            <p>凝聚空间努力让每一次邂逅总能超越期待</p>
          </div>
        </div>
        <div class="mask"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      projectList: [
        {
          img: require("@/assets/logo/wesheng.png"),
          title: "维升投票系统",
          text: "维升投票系统的主要功能是发布与分享用户利用该平台模板及配置制作的面向微信软件的投票网站。随着微信用户群体的增加，一些微信附加功能的需求也随之增加。而大部分的用户并不知道如何使用这些功能，只能求助于第三平台。而维升投票平台。"
        },{
          img: require("@/assets/logo/dida.png"),
          title: "滴答规划",
          text: "滴答规划是一款提醒事项与时间管理的APP，它会时刻提醒你珍惜自己的时间，可以精确到秒。支持分类管理功能，同时支持「TCPU面板」，「年龄计算器」，「全屏时钟」，「日期计算器」，「二维码生成」等小工具。该APP让您每分每秒都能够看到时间的变化，提醒您珍惜时间。"
        },{
          img: require("@/assets/logo/zanmb.png"),
          title: "攒面包",
          text: "攒面包是一个可以让用户快速获得活动折扣和商品折扣的平台。用户能够通过我们平台获得外卖活动以及外卖商品等分享链接，用户通过将对应链接分享给其他人。当其他人从分享链接中成功完成订单时，该用户就可以在平台中获得对应比例的佣金。"
        },{
          img: require("@/assets/logo/dandan.png"),
          title: "怪蛋大乱战",
          text: "怪蛋大乱战是一款竞技类游戏，玩家可以操作主角蛋蛋和其他玩家一起游戏，通过释放技能和拾取道具，率先到达终点者获得胜利。"
        }
      ],
      serviceList: [{
        text: "通过对用户发展状况和变化需求的充分挖掘，把用户的需求与企业服务提供优化融合。"
      },{
        text: "增强创业项目孵化、培育生命力，共享产品（服务）价值。"
      },{
        text: "让创业者能够实现资源共享，为自己的梦想创造更多的可能性。"
      },{
        text: "为企业量身定制独立的全案营销方案，全方位的为客户提供有效的解决方案。"
      },{
        text: "根据客户的需求，市场状况，企业情况等进行搭建适合客户的网站。"
      }],
      technologyList: [{
        img: require("@/assets/business/web.png"),
        title: "网站设计与开发",
        child: [{
          text: "企业形象/产品网站设计开发"
        },{
          text: "集团官方网站设计开发"
        },{
          text: "淘宝/天猫店铺视觉设计"
        },{
          text: "html5+css3"
        },{
          text: "各类型网站设计和制作"
        }]
      },{
        img: require("@/assets/business/interface.png"),
        title: "软件界面设计",
        child: [{
          text: "软件视觉设计"
        },{
          text: "Windows/Mac"
        },{
          text: "OA系统"
        },{
          text: "管理系统界面"
        },{
          text: "其他终端"
        }]
      },{
        img: require("@/assets/business/design.png"),
        title: "品牌VIS设计",
        child: [{
          text: "Logo设计"
        },{
          text: "平面设计"
        },{
          text: "视觉识别系统"
        },{
          text: "画册设计"
        }]
      },{
        img: require("@/assets/business/mobile.png"),
        title: "移动产品设计",
        child: [{
          text: "移动界面设计"
        },{
          text: "iOS/Android界面设计"
        },{
          text: "微信公众平台"
        },{
          text: "移动网站设计开发"
        }]
      },{
        img: require("@/assets/business/other.png"),
        title: "其他增值服务",
        child: [{
          text: "网站维护与管理"
        },{
          text: "域名注册"
        },{
          text: "服务器租赁"
        }]
      }]
    }
  },
}
</script>

<style scoped>
@import '../../commonScss/style.css';

.content_Container {
  padding: 50px 0;
}

.project, .service, .technology, .encounter .container_size {
  width: 1200px;
  min-width: 1200px;
  margin: 0 auto;
}

.project .project_list,
.service .service_list  {
  margin: 0 100px;
  font-size: 18px;
  color: #555;
}

.project_list .project_block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.project_list .project_block:nth-child(n + 2) {
  margin-top: 30px;
}

.project_block .block_left img {
  width: 150px;
}

.project_block .block_right {
  width: calc(100% - 200px);
}

.project_block .block_right .right_title span {
  font-size: 25px;
  font-weight: 600;
  color: #333;
  display: inline-block;
  position: relative;
  letter-spacing: 2px;
}

.project_block .block_right .right_title span::after {
  content: "";
  width: 100%;
  height: 4px;
  background-color: #ffea32;
  color: #ffea32;
  display: block;
}

.project_block .block_right .right_text {
  margin-top: 10px;
  text-indent: 2em;
  line-height: 30px;
}

.service,
.technology {
  margin-top: 50px;
}

.service .service_list ol {
  line-height: 35px;
}

.bottom_bg {
  width: 100%;
  background: url("../../assets/business/bottomBg.png");
  background-size: 100% 100%;
}

.technology .technology_list {
  margin: 0 75px;
  font-size: 18px;
  color: #555;
  position: relative;
}

.technology_list .line {
  width: 95%;
  height: 1px;
  background-color: #eee;
  position: absolute;
  top: 35px;
  z-index: -1;
}

.technology_list .list_container {
  display: flex;
  justify-content: space-between;
  margin-left: 50px;
}

.list_container .technology_block {
  width: 180px;
}

.technology_block .block_img {
  width: 70px;
  height: 70px;
  border: 1px solid #cacaca;
  border-radius: 50%;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.block_img img {
  width: 35px;
  height: 35px;
}

.block_text {
  margin-top: 15px;
}

.block_text p {
  margin-top: 0;
  margin-bottom: 10px;
}

.block_text .title_p {
  color: #333;
  font-weight: 600;
}

.block_text p:nth-child(n + 2) {
  color: #a6a6a6;
  font-weight: 600;
  font-size: 12px;
}

.encounter {
  margin-top: 50px;
  padding: 45px 0;
  background-image: url("../../assets/business/contactBg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center top;
  position: relative;
}

.encounter .mask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.container_size .text_container {
  position: relative;
  margin: 0 100px;
  z-index: 100;
}

.text_container h6, .text_container p {
  margin: 0;
}

.text_container h6 {
  font-size: 30px;
  color: #fff;
  line-height: 40px;
  font-weight: normal;
}

.text_container p {
  line-height: 50px;
  font-size: 18px;
  color: #eee;
}
</style>